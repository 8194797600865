.logo_wrapper {
  height: 50vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
}

.screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  /* background-color: #55198b; */
  overflow: hidden;
  border-radius: 100%;
  animation: grow 5.5s forwards;
}

.loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ball {
  width: 90px;
  height: 10px;
  margin: 7px auto;
  border-radius: 50px;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  90% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  100% {
    transform: scale(0);
    transform-origin: 50% 50%;
    border-radius: 100%;
  }
}

#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-animation .ball{
  animation: circle 1s infinite ease-in-out;
}
.ball:nth-child(1) { background: #00f7ff; animation-duration: 0.90s;}
.ball:nth-child(2) { background: #35ff99; animation-duration: 0.95s;}
.ball:nth-child(3) { background: #008597; animation-duration: 1.00s;}
.ball:nth-child(4) { background: #009dffbd; animation-duration: 1.05s;}
.ball:nth-child(5) { background: #2d3443; animation-duration: 1.10s;}
.ball:nth-child(6) { background: #35d0ff; animation-duration: 1.15s;}
.ball:nth-child(7) { background: #115555; animation-duration: 1.20s;}

@keyframes circle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
